<template>
  <div class="popup_wrap" style="width:900px;height:600px;">
    <!-- 팝업사이즈 참고 : 900*600  popup_wrap -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="frm">
      <div class="popup_cont">
        <!-- popup_cont -->
        <h1 class="page_title">{{ $t('msg.CSCT060P080.001') }}</h1><!-- 입고정보/Measure 등록 -->
        <div class="content_box">
          <!-- content_box -->
          <h2 class="content_title">{{ $t('msg.CSCT060P080.002') }}</h2><!-- 입고 상세 -->
          <table class="tbl_row">
            <colgroup>
              <col width="130"><col><col width="130"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.CSCT060P080.003') }}</th><!-- 입고번호 -->
                <td>
                  <e-input-number
                    v-model="params.whsgNo"
                    :id="'ipt_whsg_no'"
                    :readonly="params.flagCnt !== '1'"
                    @input="checkWhsgNo()"
                    :digit-max="4"
                  />
                </td>
                <th>{{ $t('msg.CSCT060P080.004') }}</th><!-- 입고일자/시간 -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_5">
                      <e-date-picker
                        v-model="params.whsgDt"
                        :id="'ipt_whsg_dt'"
                        :readonly="params.flagCnt !== '1'"
                      />
                    </span>
                    <span class="">
                      <!-- <e-input-number
                        v-model="params.whsgDrtm"
                        :id="'ipt_whsg_drtm'"
                        :readonly="params.flagCnt !== '1'"
                        :digitMax=4
                        @input="checkWhsgDrtm()"
                      /> -->
                      <div>
                        <input
                          v-model="params.whsgDrtm"
                          type="text"
                          id="ipt_whsg_drtm"
                          :readonly="params.flagCnt !== '1'"
                          @keypress="onKeypress($event)"
                          @keyup="checkWhsgDrtm()"
                        />
                      </div>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.CSCT060P080.005') }}</th><!-- 도착지 -->
                <td>
                  <div class="tbl_form">
                    <e-auto-complete-place
                      id="podPod"
                      @change="changePod"
                      :ctr-cd="params.podCtrCd"
                      :plc-cd="params.podPortCd"
                      :read-only="params.flagCnt !== '1'"
                    />
                  </div>
                </td>
                <th>{{ $t('msg.CSCT060P080.006') }}</th><!-- 화주명 -->
                <td>
                  <e-auto-complete-cst-enm
                    id="actCst"
                    @change="changeAct"
                    :ctr-cd="params.cstCd"
                    :cd-nm="params.cstNm"
                    :read-only="params.flagCnt !== '1'"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.CSCT060P080.007') }}</th><!-- 수량/중량(kg) -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_5">
                      <div>
                        <e-input-number
                          v-model="params.qty"
                          :id="'ipt_qty'"
                          :readonly="params.flagCnt !== '1'"
                          @input="checkQty()"
                          :digit-max="3"
                        />
                      </div>
                    </span>
                    <span class="wid20 text_center"> / </span>
                    <span class="dv">
                      <div>
                        <e-input-number
                          v-model="params.wt"
                          :readonly="params.flagCnt !== '1'"
                          :is-comma="true"
                          :point="3"
                          :digit-max="5"
                        />
                      </div>
                    </span>
                  </div>
                </td>
                <th>{{ $t('msg.CSCT060P080.008') }}</th><!-- CBM -->
                <td>
                  <e-input-number
                    v-model="params.grsCbm"
                    :readonly="params.flagCnt !== '1'"
                    :is-comma="true"
                    :point="4"
                    :digit-max="8"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.CSCT060P080.009') }}</th><!-- 컨테이너 번호 -->
                <td>
                  <input
                    v-model="params.cntrNo"
                    type="text"
                    :readonly="params.flagCnt !== '1' && params.flagCnt !== '3'"
                  >
                </td>
                <th>{{ $t('msg.CSCT060P080.010') }}</th><!-- Seal 번호 -->
                <td>
                  <input
                    v-model="params.sealNo1"
                    type="text"
                    :readonly="params.flagCnt !== '1' && params.flagCnt !== '3'"
                  >
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.CSCT060P080.011') }}</th><!-- Booking 번호 -->
                <td>
                  <input
                    v-model="params.bkgNo"
                    type="text"
                    :readonly="params.flagCnt !== '1' && params.flagCnt !== '3'"
                  >
                </td>
                <th>{{ $t('msg.CSCT060P080.012') }}</th><!-- 비고 -->
                <td>
                  <input
                    v-model="params.rmk"
                    type="text"
                    :readonly="params.flagCnt !== '1'"
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">{{ $t('msg.CSCT060P080.013') }}</h2><!-- Measure 상세 -->
          <table class="tbl_col">
            <colgroup>
              <col width="8%">
              <col width="16%">
              <col width="16%">
              <col width="16%">
              <col width="16%">
              <col width="16%">
              <col width="12%">
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.CSCT060P080.014') }}</th><!-- 순번 -->
                <th>{{ $t('msg.CSCT060P080.015') }}</th><!-- 가로(m) -->
                <th>{{ $t('msg.CSCT060P080.016') }}</th><!-- 세로(m) -->
                <th>{{ $t('msg.CSCT060P080.017') }}</th><!-- 높이(m) -->
                <th>{{ $t('msg.CSCT060P080.018') }}</th><!-- 수량 -->
                <th>{{ $t('msg.CSCT060P080.019') }}</th><!-- 용적(㎥) -->
                <!-- <th>추가/삭제</th> -->
                <th>
                  <button
                    type="button"
                    class="tbl_icon plus"
                    @click="addMeasureInfo()"
                  >
                    plus
                  </button>
                </th>
              </tr>
              <tr
                v-for="(cfsMeasureInfo, idx) in params.cfsMeasureInfoList"
                :key="'tr_cfsMeasureInfo_' + idx"
              >
                <td>{{ cfsMeasureInfo.seq }}</td>
                <td>
                  <e-input-number
                    v-model="cfsMeasureInfo.widt"
                    :id="'widt_' + idx"
                    :readonly="params.flagCnt !== '2'"
                    :is-comma="true"
                    :point="3"
                    :digit-max="2"
                    @input="fnCapySet(idx);fnValidationMeasure()"
                  />
                </td>
                <td>
                  <e-input-number
                    v-model="cfsMeasureInfo.len"
                    :id="'len_' + idx"
                    :readonly="params.flagCnt !== '2'"
                    :is-comma="true"
                    :point="3"
                    :digit-max="2"
                    @input="fnCapySet(idx);fnValidationMeasure()"
                  />
                </td>
                <td>
                  <e-input-number
                    v-model="cfsMeasureInfo.hght"
                    :id="'hght_' + idx"
                    :readonly="params.flagCnt !== '2'"
                    :is-comma="true"
                    :point="3"
                    :digit-max="2"
                    @input="fnCapySet(idx);fnValidationMeasure()"
                  />
                </td>
                <td>
                  <e-input-number
                    v-model="cfsMeasureInfo.qty"
                    :id="'qty_' + idx"
                    :readonly="params.flagCnt !== '2'"
                    :is-comma="true"
                    :digit-max="2"
                    @input="fnCapySet(idx);fnValidationMeasure()"
                  />
                </td>
                <td>
                  <e-input-number
                    v-model="cfsMeasureInfo.capy"
                    :id="'capy_' + idx"
                    :readonly="true"
                    :is-comma="true"
                    :point="3"
                  />
                </td>
                <td>
                  <!-- <button class="tbl_icon plus">plus</button> -->
                  <button
                    type="button"
                    class="tbl_icon minus"
                    @click="removeMeasureInfo(idx)"
                  >
                    minus
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="text_center">{{ $t('msg.CSCT060P080.020') }}</td><!-- SUB TOTAL -->
                <td>{{ params.sumQty }}</td>
                <td>{{ params.sumCapy }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div class="mt10 text_center">
          <!-- <a class="button blue lg" href="#">초기화</a> -->
          <a
            v-if="params.flagCnt === '1' || params.flagCnt === '2' || params.flagCnt === '3'"
            class="button blue lg"
            href="#"
            @click.prevent="fnCfsSave()"
          >{{ $t('msg.CSCT060P080.021') }}</a><!-- 저장 -->
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close')"
          >{{ $t('msg.CSCT060P080.022') }}</a><!-- 닫기 -->
        </div>
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>

import { rootComputed } from '@/store/helpers'

import intergis from '@/api/rest/common/intergis'

// import moment from 'moment'

export default {
  name: 'IntergisCfsDetailPop',
  components: {
    'e-date-picker': () => import('@/components/common/EDatePicker'),
    'e-auto-complete-place': () => import('@/components/common/EAutoCompletePlace'),
    'e-auto-complete-cst-enm': () => import('@/components/common/EAutoCompleteCstEnmV2'),
    'e-input-number': () => import('@/components/common/EInputNumber')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          reqRno: '',
          flag: 'REG'
        }
      }
    }
  },
  data: function () {
    return {
      params: {
        reqRno: '',
        // ============ [s] 입고 상세 ============
        // 입고번호
        whsgNo: '',
        // 입고일자/시간
        whsgDt: '',
        whsgDrtm: '',
        // 도착지
        podCtrCd: '',
        podPortCd: '',
        // 화주명
        cstCd: 'KR',
        cstNm: '',
        // 수량/중량(kg)
        qty: '',
        wt: '',
        // CBM
        grsCbm: '',
        // 컨테이너 번호
        cntrNo: '',
        // Seal 번호
        sealNo1: '',
        // Booking 번호
        bkgNo: '',
        // 비고
        rmk: '',
        // ============ [e] 입고 상세 ============

        // ============ [s] Measure 상세 ============
        cfsMeasureInfoList: [
          {
            seq: '1',
            widt: '',
            len: '',
            hght: '',
            qty: '',
            capy: ''
          }
        ],
        sumQty: 0,
        sumCapy: 0,
        // ============ [e] Measure 상세 ============

        // 인터지스 플래그 (1 or 3 => 입고 정보만 입력가능 // 2 => Measure 업체 & Measure 입력가능)
        flagCnt: '',
        // 등록/수정 구분 코드
        addFlag: this.parentInfo.flag
      }
    }
  },
  watch: {
    'params.whsgDt': function (val) {
      this.checkWhsgDt()
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.auth !== undefined) {
      this.params.cstCd = this.auth.cstCd
    }
    if (this.memberDetail !== undefined) {
      this.params.flagCnt = this.memberDetail.intergisLv

      //test
      // this.params.flagCnt = '1'
      // this.params.flagCnt = '2'
      // this.params.flagCnt = '3'
    }

    const reqRno = this.parentInfo.reqRno

    // 수정 화면인 경우
    if (reqRno !== '') {
      intergis.popCfsReq({ reqRno: reqRno })
        .then((response) => {
          const resp = response.data
          const cfsInfoDetail = resp.cfsInfoDetail
          const cfsMeasureInfoList = resp.cfsMeasureInfoList

          // 입고 상세 세팅
          if (cfsInfoDetail !== undefined) {
            this.params = { ...this.params, ...cfsInfoDetail }
            this.params.whsgDrtm = this.params.whsgDrtm.substring(0, 2) + ':' + this.params.whsgDrtm.substring(2, 4)
          }
          // Measure 상세 세팅
          if (cfsMeasureInfoList !== undefined && cfsMeasureInfoList.length > 0) {
            this.params.cfsMeasureInfoList = cfsMeasureInfoList
          }

          this.fnSubTotal()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    // '도착지' 자동완성 change event
    changePod (vo) {
      this.params.podCtrCd = vo.ctrCd
      this.params.podPortCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // '화주명' 자동완성 change event
    changeAct (vo) {
      this.params.cstNm = vo.cdNm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#actCst')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    //행 추가
    addMeasureInfo () {
      if (this.params.flagCnt !== '2') {
        return
      }

      const cfsMeasureInfoList = this.params.cfsMeasureInfoList
      const listLen = cfsMeasureInfoList.length
      const seq = Number(cfsMeasureInfoList[listLen - 1].seq) + 1

      cfsMeasureInfoList.push(
        {
          seq: seq,
          widt: '',
          len: '',
          hght: '',
          qty: '',
          capy: ''
        }
      )
    },
    removeMeasureInfo (idx) {
      if (this.params.flagCnt !== '2') {
        return
      }

      const listLen = this.params.cfsMeasureInfoList.length
      let cnt = 1

      if (listLen === 1) {
        this.params.cfsMeasureInfoList[0].widt = ''
        this.params.cfsMeasureInfoList[0].len = ''
        this.params.cfsMeasureInfoList[0].hght = ''
        this.params.cfsMeasureInfoList[0].qty = ''
        this.params.cfsMeasureInfoList[0].capy = ''

        this.fnSubTotal()
        return
      }
      this.params.cfsMeasureInfoList.splice(idx, 1)
      this.params.cfsMeasureInfoList.forEach(element => {
        element.seq = cnt++
      })

      this.fnSubTotal()
    },
    fnSubTotal () {
      if (this.params.cfsMeasureInfoList === undefined || this.params.cfsMeasureInfoList.length === 0) {
        return
      }

      this.params.sumQty = this.params.cfsMeasureInfoList.reduce((sum, currValue) => {
        return sum + Number(currValue.qty)
      }, 0)

      this.params.sumCapy = this.params.cfsMeasureInfoList.reduce((sum, currValue) => {
        return sum + Number(currValue.capy)
      }, 0)

      this.params.sumCapy = Number(this.params.sumCapy).toFixed(3)
    },
    fnCfsSave () {
      const frm = document.querySelector('#frm')
      let msg = ''

      if (this.fnValidationAll(frm)) {
        // 입고 정보가 기존에 저장되어 있는 상태에서
        // Measure 정보를 저장할 때, 생성했던 요청번호(reqRno)를 넣어줘야 한다.
        if (this.params.addFlag === 'UPD') {
          this.params.cfsMeasureInfoList.forEach(cfsMeasureInfo => {
            cfsMeasureInfo.reqRno = this.params.reqRno
          })
        }

        const formData = JSON.parse(JSON.stringify(this.params))
        formData.whsgDrtm = formData.whsgDrtm.replace(':', '')

        intergis.insertCfsReq(formData)
          .then((response) => {
            if (response.headers.respcode === 'C0000') {
              msg = this.params.addFlag === 'REG' ? this.$t('msg.CSCT060P080.023') : this.$t('msg.CSCT060P080.024') // 등록 성공 or 수정 성공
              this.$ekmtcCommon.alertCallback(msg, () => { this.$emit('close') })
            } else {
              msg = this.params.addFlag === 'REG' ? this.$t('msg.CSCT060P080.025') : this.$t('msg.CSCT060P080.026') // 등록 실패 or 수정 실패
              this.$ekmtcCommon.alertDefault(msg)
            }
          })
      }
    },
    // insert 전 전체 validation check
    fnValidationAll (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      let isAllOk = true

      // 로그인 세션에 따른 필수값 입력 체크 추가 필요
      // 인터지스 필수 체크
      if (this.params.flagCnt === '1') {
        isAllOk = this.fnValidationIntergis(frm)
      }
      //Mearsure 필수 체크
      if (this.params.flagCnt === '2') {
        isAllOk = this.fnValidationMeasure(frm)
      }

      return isAllOk
    },
    // 인터지스 입력항목 validation check
    fnValidationIntergis (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      let isIntergisOk = true

      if (!this.checkWhsgNo(frm)) {
        isIntergisOk = false
      }
      if (!this.checkWhsgDt(frm)) {
        isIntergisOk = false
      }
      if (!this.checkWhsgDrtm(frm)) {
        isIntergisOk = false
      }
      if (!this.checkPod(frm)) {
        isIntergisOk = false
      }
      if (!this.checkActCst(frm)) {
        isIntergisOk = false
      }
      if (!this.checkQty(frm)) {
        isIntergisOk = false
      }

      return isIntergisOk
    },
    // [s] 인터지스 입력항목 check ==================================================
    // '입고번호' check
    checkWhsgNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemWhsgNo = frm.querySelector('#ipt_whsg_no')
      // const valWhsgNo = elemWhsgNo.value
      const valWhsgNo = this.params.whsgNo
      let isOk = true

      if (valWhsgNo === '') {
        let msg = this.$t('msg.CSCT060P080.027') // 입고 번호를 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemWhsgNo, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemWhsgNo)
      }

      return isOk
    },
    // '입고일자' check
    checkWhsgDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemWhsgDt = frm.querySelector('#ipt_whsg_dt')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.whsgDt)) {
        let msg = this.$t('msg.CSCT060P080.028') // 입고일자를 입력하세요.'
        this.$ekmtcCommon.showErrorTooltip(elemWhsgDt, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemWhsgDt)
      }

      return isOk
    },
    // '입고시간' check
    checkWhsgDrtm (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemWhsgDrtm = frm.querySelector('#ipt_whsg_drtm')
      let msg = ''
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.whsgDrtm)) {
        msg = this.$t('msg.CSCT060P080.029') // 입고시간을 입력하세요.'
        this.$ekmtcCommon.showErrorTooltip(elemWhsgDrtm, msg)
        isOk = false
      } else {
        const tmpWhsgDrtm = this.params.whsgDrtm.replace(':', '')

        // 숫자가 4개 들어왔을 때
        if (tmpWhsgDrtm.length === 4) {
          const timeRegExp = /^([1-9]|[01][0-9]|2[0-3])([0-5][0-9])$/
          // 입력한 값이 시간 형식일 때
          if (timeRegExp.test(this.params.whsgDrtm)) {
            this.params.whsgDrtm = this.params.whsgDrtm.substring(0, 2) + ':' + this.params.whsgDrtm.substring(2, 4)
            this.$ekmtcCommon.hideErrorTooltip(elemWhsgDrtm)
          } else {
            const setTimeRegExp = /^([1-9]|[01][0-9]|2[0-3]):([0-5][0-9])$/

            if (!setTimeRegExp.test(this.params.whsgDrtm)) {
              msg = this.$t('msg.CSCT060P080.030') // HHMM(24시 기준) 헝식으로 작성해 주세요.
              this.$ekmtcCommon.showErrorTooltip(elemWhsgDrtm, msg)
              isOk = false
            }
          }
        } else {
          msg = this.$t('msg.CSCT060P080.031') // 숫자 4자리를 작성해 주세요.
          this.$ekmtcCommon.showErrorTooltip(elemWhsgDrtm, msg)
          isOk = false
        }
      }

      return isOk
    },
    // '도착지' check
    checkPod (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemPod = frm.querySelector('#podPod')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.podCtrCd) || this.$ekmtcCommon.isEmpty(this.params.podPortCd)) {
        let msg = this.$t('msg.CSCT060P080.032') // 도착지를 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemPod, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemPod)
      }

      return isOk
    },
    // '화주명' check
    checkActCst (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemActCst = frm.querySelector('#actCst')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.cstNm)) {
        let msg = this.$t('msg.CSCT060P080.033') // 화주명을 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemActCst, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemActCst)
      }

      return isOk
    },
    // '수량' check
    checkQty (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemQty = frm.querySelector('#ipt_qty')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.qty)) {
        let msg = this.$t('msg.CSCT060P080.034') // 수량을 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemQty, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemQty)
      }

      return isOk
    },
    // [e] 인터지스 입력항목 check ==================================================

    // Measure 입력항목 validation check
    fnValidationMeasure (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      let isMeasureOk = true
      let accuRet = 1
      let elem = ''
      let msg = ''

      this.params.cfsMeasureInfoList.forEach((cfsMeasureInfo, index) => {
        // '가로' 체크
        elem = frm.querySelector('#widt_' + index)
        if (this.$ekmtcCommon.isEmpty(cfsMeasureInfo.widt)) {
          accuRet *= 0
          msg = this.$t('msg.CSCT060P080.035') // 가로길이를 입력하세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          // accuRet *= 1
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        // '세로' 체크
        elem = frm.querySelector('#len_' + index)
        if (this.$ekmtcCommon.isEmpty(cfsMeasureInfo.len)) {
          accuRet *= 0
          msg = this.$t('msg.CSCT060P080.036') // 가로길이를 입력하세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          // accuRet *= 1
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        // '높이' 체크
        elem = frm.querySelector('#hght_' + index)
        if (this.$ekmtcCommon.isEmpty(cfsMeasureInfo.hght)) {
          accuRet *= 0
          msg = this.$t('msg.CSCT060P080.037') // 높이를 입력하세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          // accuRet *= 1
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        // '수량' 체크
        elem = frm.querySelector('#qty_' + index)
        if (this.$ekmtcCommon.isEmpty(cfsMeasureInfo.qty) || Number(cfsMeasureInfo.qty) === 0) {
          accuRet *= 0
          msg = this.$t('msg.CSCT060P080.038') // 수량을 입력하세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          // accuRet *= 1
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        // '용적' 체크
        elem = frm.querySelector('#capy_' + index)

        const len = String(cfsMeasureInfo.capy).indexOf('.') > -1 ? cfsMeasureInfo.capy.split('.')[0].length : String(cfsMeasureInfo.capy).length
        if (len > 4) {
          accuRet *= 0
          msg = this.$t('msg.CSCT060P080.039') // 용적은 정수 4자리까지 입력가능합니다.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          // accuRet *= 1
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
      })

      if (accuRet === 0) {
        isMeasureOk = false
      }

      return isMeasureOk
    },
    onKeypress (e) {
      const keyCode = e.keyCode
      // 숫자
      if ((keyCode >= 48 && keyCode <= 57) && this.params.whsgDrtm.length < 4) {
        return
      }
      e.preventDefault()
    },
    fnCapySet (idx) {
      let capy = 0

      if (this.$ekmtcCommon.isNotEmpty(this.params.cfsMeasureInfoList[idx].widt) && this.$ekmtcCommon.isNotEmpty(this.params.cfsMeasureInfoList[idx].len) && this.$ekmtcCommon.isNotEmpty(this.params.cfsMeasureInfoList[idx].hght) && this.$ekmtcCommon.isNotEmpty(this.params.cfsMeasureInfoList[idx].qty)) {
        capy = Number(this.params.cfsMeasureInfoList[idx].widt) * Number(this.params.cfsMeasureInfoList[idx].len) * Number(this.params.cfsMeasureInfoList[idx].hght) * Number(this.params.cfsMeasureInfoList[idx].qty)
        capy = capy.toFixed(3)
      }

      this.params.cfsMeasureInfoList[idx].capy = capy

      this.fnSubTotal()
    }
  }
}
</script>
