var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("form", { attrs: { id: "frm" } }, [
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.CSCT060P080.001"))),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.CSCT060P080.002"))),
            ]),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.003")))]),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        attrs: {
                          id: "ipt_whsg_no",
                          readonly: _vm.params.flagCnt !== "1",
                          "digit-max": 4,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkWhsgNo()
                          },
                        },
                        model: {
                          value: _vm.params.whsgNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "whsgNo", $$v)
                          },
                          expression: "params.whsgNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.004")))]),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c(
                        "span",
                        { staticClass: "dv col_5" },
                        [
                          _c("e-date-picker", {
                            attrs: {
                              id: "ipt_whsg_dt",
                              readonly: _vm.params.flagCnt !== "1",
                            },
                            model: {
                              value: _vm.params.whsgDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "whsgDt", $$v)
                              },
                              expression: "params.whsgDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", {}, [
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.params.whsgDrtm,
                                expression: "params.whsgDrtm",
                              },
                            ],
                            attrs: {
                              type: "text",
                              id: "ipt_whsg_drtm",
                              readonly: _vm.params.flagCnt !== "1",
                            },
                            domProps: { value: _vm.params.whsgDrtm },
                            on: {
                              keypress: function ($event) {
                                return _vm.onKeypress($event)
                              },
                              keyup: function ($event) {
                                return _vm.checkWhsgDrtm()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.params,
                                  "whsgDrtm",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.005")))]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "tbl_form" },
                      [
                        _c("e-auto-complete-place", {
                          attrs: {
                            id: "podPod",
                            "ctr-cd": _vm.params.podCtrCd,
                            "plc-cd": _vm.params.podPortCd,
                            "read-only": _vm.params.flagCnt !== "1",
                          },
                          on: { change: _vm.changePod },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.006")))]),
                  _c(
                    "td",
                    [
                      _c("e-auto-complete-cst-enm", {
                        attrs: {
                          id: "actCst",
                          "ctr-cd": _vm.params.cstCd,
                          "cd-nm": _vm.params.cstNm,
                          "read-only": _vm.params.flagCnt !== "1",
                        },
                        on: { change: _vm.changeAct },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.007")))]),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_5" }, [
                        _c(
                          "div",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "ipt_qty",
                                readonly: _vm.params.flagCnt !== "1",
                                "digit-max": 3,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkQty()
                                },
                              },
                              model: {
                                value: _vm.params.qty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "qty", $$v)
                                },
                                expression: "params.qty",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "wid20 text_center" }, [
                        _vm._v(" / "),
                      ]),
                      _c("span", { staticClass: "dv" }, [
                        _c(
                          "div",
                          [
                            _c("e-input-number", {
                              attrs: {
                                readonly: _vm.params.flagCnt !== "1",
                                "is-comma": true,
                                point: 3,
                                "digit-max": 5,
                              },
                              model: {
                                value: _vm.params.wt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "wt", $$v)
                                },
                                expression: "params.wt",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.008")))]),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        attrs: {
                          readonly: _vm.params.flagCnt !== "1",
                          "is-comma": true,
                          point: 4,
                          "digit-max": 8,
                        },
                        model: {
                          value: _vm.params.grsCbm,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "grsCbm", $$v)
                          },
                          expression: "params.grsCbm",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.009")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.cntrNo,
                          expression: "params.cntrNo",
                        },
                      ],
                      attrs: {
                        type: "text",
                        readonly:
                          _vm.params.flagCnt !== "1" &&
                          _vm.params.flagCnt !== "3",
                      },
                      domProps: { value: _vm.params.cntrNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "cntrNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.010")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.sealNo1,
                          expression: "params.sealNo1",
                        },
                      ],
                      attrs: {
                        type: "text",
                        readonly:
                          _vm.params.flagCnt !== "1" &&
                          _vm.params.flagCnt !== "3",
                      },
                      domProps: { value: _vm.params.sealNo1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "sealNo1", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.011")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.bkgNo,
                          expression: "params.bkgNo",
                        },
                      ],
                      attrs: {
                        type: "text",
                        readonly:
                          _vm.params.flagCnt !== "1" &&
                          _vm.params.flagCnt !== "3",
                      },
                      domProps: { value: _vm.params.bkgNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "bkgNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.012")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.rmk,
                          expression: "params.rmk",
                        },
                      ],
                      attrs: {
                        type: "text",
                        readonly: _vm.params.flagCnt !== "1",
                      },
                      domProps: { value: _vm.params.rmk },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "rmk", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.CSCT060P080.013"))),
            ]),
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(1),
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.014")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.015")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.016")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.017")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.018")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.019")))]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addMeasureInfo()
                            },
                          },
                        },
                        [_vm._v(" plus ")]
                      ),
                    ]),
                  ]),
                  _vm._l(
                    _vm.params.cfsMeasureInfoList,
                    function (cfsMeasureInfo, idx) {
                      return _c("tr", { key: "tr_cfsMeasureInfo_" + idx }, [
                        _c("td", [_vm._v(_vm._s(cfsMeasureInfo.seq))]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "widt_" + idx,
                                readonly: _vm.params.flagCnt !== "2",
                                "is-comma": true,
                                point: 3,
                                "digit-max": 2,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.fnCapySet(idx)
                                  _vm.fnValidationMeasure()
                                },
                              },
                              model: {
                                value: cfsMeasureInfo.widt,
                                callback: function ($$v) {
                                  _vm.$set(cfsMeasureInfo, "widt", $$v)
                                },
                                expression: "cfsMeasureInfo.widt",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "len_" + idx,
                                readonly: _vm.params.flagCnt !== "2",
                                "is-comma": true,
                                point: 3,
                                "digit-max": 2,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.fnCapySet(idx)
                                  _vm.fnValidationMeasure()
                                },
                              },
                              model: {
                                value: cfsMeasureInfo.len,
                                callback: function ($$v) {
                                  _vm.$set(cfsMeasureInfo, "len", $$v)
                                },
                                expression: "cfsMeasureInfo.len",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "hght_" + idx,
                                readonly: _vm.params.flagCnt !== "2",
                                "is-comma": true,
                                point: 3,
                                "digit-max": 2,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.fnCapySet(idx)
                                  _vm.fnValidationMeasure()
                                },
                              },
                              model: {
                                value: cfsMeasureInfo.hght,
                                callback: function ($$v) {
                                  _vm.$set(cfsMeasureInfo, "hght", $$v)
                                },
                                expression: "cfsMeasureInfo.hght",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "qty_" + idx,
                                readonly: _vm.params.flagCnt !== "2",
                                "is-comma": true,
                                "digit-max": 2,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.fnCapySet(idx)
                                  _vm.fnValidationMeasure()
                                },
                              },
                              model: {
                                value: cfsMeasureInfo.qty,
                                callback: function ($$v) {
                                  _vm.$set(cfsMeasureInfo, "qty", $$v)
                                },
                                expression: "cfsMeasureInfo.qty",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "capy_" + idx,
                                readonly: true,
                                "is-comma": true,
                                point: 3,
                              },
                              model: {
                                value: cfsMeasureInfo.capy,
                                callback: function ($$v) {
                                  _vm.$set(cfsMeasureInfo, "capy", $$v)
                                },
                                expression: "cfsMeasureInfo.capy",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeMeasureInfo(idx)
                                },
                              },
                            },
                            [_vm._v(" minus ")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text_center", attrs: { colspan: "4" } },
                      [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.020")))]
                    ),
                    _c("td", [_vm._v(_vm._s(_vm.params.sumQty))]),
                    _c("td", [_vm._v(_vm._s(_vm.params.sumCapy))]),
                    _c("td"),
                  ]),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _vm.params.flagCnt === "1" ||
            _vm.params.flagCnt === "2" ||
            _vm.params.flagCnt === "3"
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnCfsSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.021")))]
                )
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSCT060P080.022")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }